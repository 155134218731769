div {
    font-family:  "Timew New Roman", sans-serif;
    font-size: 12px;
}



/*
    The example loads the DejaVu Sans from the Kendo UI CDN.
    Other fonts have to be hosted from your application.
    The official site of the Deja Vu Fonts project is
    https://dejavu-fonts.github.io/.
*/
@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}